:root {
  --custom-background: #fff;
  --sampoll-theme-color: #597ef7;
  --max-width: 1200px;
  --doc-height: 99vh;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono', 'Roboto Mono', 'Oxygen Mono',
    'Ubuntu Monospace', 'Source Code Pro', 'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(#00000080, #00000040, #00000030, #00000020, #00000010, #00000010, #00000080);

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(to bottom right, rgba(1, 65, 255, 0), rgba(1, 65, 255, 0), rgba(1, 65, 255, 0.3));

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(#ffffff80, #ffffff40, #ffffff30, #ffffff20, #ffffff10, #ffffff10, #ffffff80);

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  max-width: 100vw;
  height: 100%;
  overflow-x: hidden;
  font-family: var(--nunito-font);
  font-weight: 300;
}

#nprogress .bar {
  height: 6px !important;
  background-color: #597ef7 !important;
}

h1 {
  font-family: var(--righteous-font);
  font-weight: 400;
}

.stickyfooter_image {
  border-radius: 5px 5px 0 0; /* Change this value to adjust the size of the radius */
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25); /* Adjust this to fit your needs */
  overflow: hidden;
  position: fixed;
  bottom: 0;
  transition: opacity 0.5s ease;
  z-index: 999;
  width: 150px;
  height: 26px;
  left: 50%;
  transform: translateX(-50%);

  &.hidden {
    opacity: 0;
  }

  &.visible {
    opacity: 1;
  }
}

.poweredby-image {
  display: block;
  --amplify-components-image-object-fit: cover;
}

// Below are multiselect css properties
.searchWrapper {
  border-radius: 10px !important;
  border: 1px solid rgb(0 0 0 / 29%);
  min-height: 40px !important;
  max-width: 18rem !important;
  padding: 5px;
  position: relative;
}
.chip {
  background: var(--sampoll-theme-color) !important;
  color: #fff;
}
.singleChip {
  color: #ffffff !important;
}

.highlightOption {
  background: var(--sampoll-theme-color) !important;
  color: #fff;
}

.option {
  &:hover {
    background-color: var(--sampoll-theme-color) !important;
  }
}

.container {
  max-width: 800px;
  height: auto;
  margin: 0 auto;
  padding: 0 15px;
  background-position: 50% 100%;
  background-repeat: no-repeat;
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 1200px;
    padding: 0 30px;
  }

  .searchWrapper {
    max-width: 22.3rem !important;
  }
}

//Amplify UI Components CSS Overiding
.amplify-autocomplete__menu__option--active {
  background-color: #597ef7 !important;
}

.amplify-button--link {
  color: #597ef7 !important;
  &:hover {
    background-color: #597ef7;
    color: white !important;
  }
}

.amplify-input:focus {
  border-color: #597ef7 !important;
  box-shadow: rgba(89, 126, 247, 0.15) 0px 8px 24px, rgba(89, 126, 247, 0.15) 0px 16px 56px,
    rgba(89, 126, 247, 0.15) 0px 24px 80px !important;
}

.amplify-switch-track--checked {
  background-color: #597ef7 !important;
  border: none !important;
  border-color: #597ef7 !important;
  box-shadow: none !important;
}

.amplify-switch-track--focused {
  box-shadow: none !important;
}

.amplify-checkbox__icon--checked {
  background-color: #597ef7 !important;
}

.amplify-table__caption {
  caption-side: top !important;
  font-weight: 800 !important;
  font-size: 2em !important;
  font-family: var(--righteous-font);
  padding-bottom: 10px !important ;
}

.amplify-table__td {
  text-align: center !important;
}
.amplify-table__row {
  text-align: center !important;
}
